.dunkelrot {
    background-color: #cf2a2b;
}
.weißeSchrift {
    color: white;
}
.notificationLabel {
    cursor: pointer;
    align-self: center;
    padding-left: 45px;
}
.languageButton {
    width: 2.5em;
    height: 1.5em;
    padding: 0%;
    margin-left: 1.5em;
    margin-top: 0.25em;
}
.transparentButton {
    color: white;
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}
#dropdownLanguageButton {
    pointer-events: inherit;
}
#dropdownLanguageButton:active {
    border-style: outset;
    border-color: white;
    border:none;
    background-color: white;
}
#dropdownLanguageButton:focus {
    outline: 0;
}
#languageImage {
    padding: 0em;
    display: block;
    height: 100%;
    width: 100%;
}