.graueSchrift {
    color: #8c8c8c;
}
.dunkelgraueSchrift {
    color: #666666;
}
.rot {
    color: #cc0000;
}
.grün {
    color: #009900;
}
.spinner {
    margin-bottom: 2em;
}
thead {
    border: 1px solid grey;
}
main {
  padding-top: 70px;
}
.floatRight {
    float: right;
    text-align: right;
    width: 50%;
}
.floatLeft {
    float: left;
    text-align: left;
}
.alignRight {
    float: right;
    width: 4.6em;
    text-align: right;
}
.alignLeft {
    float: left;
}